import React, { useRef, useEffect } from "react";

export default function ModalWrapper({ closeModal, children }) {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && modalRef.current === event.target) {
        closeModal();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [closeModal]);

  return (
    <div
      className="
          z-skillsEligibilityCheckerModel
          fixed
          top-0 left-0
          w-full md:max-h-screen h-full
          bg-black bg-opacity-75
          grid
          place-items-center
      "
      ref={modalRef}
    >
      <div
        className="
          bg-white
          w-full
          max-w-full md:max-w-11/12 xl:max-w-5xl
          overflow-hidden
          shadow-2xl
          p-4
      "
        tabIndex={0}
      >
        {children}
      </div>
    </div>
  );
}
