import React from 'react'

// Local
import Button from '../../../../shared/Button/Button'

// Assets
import LogoSymbolRed from "../../../../../assets/img/logo-symbol-red.svg";
import SkillsForLife from '../../../../../assets/png/skills-for-life-bootcamps.png';

export default function Fail({
    closeModal
}) {
    return (
        <div
            id="content"
            className="relative min-h-500px grid items-center align-middle"
        >
            <div className='z-skillsEligibilityCheckerModelContent'>
            <img src={SkillsForLife} alt="Skills for Life" className="w-32 mx-auto mb-12" />
                <p className="text-center font-medium">
                    Based on your responses, you do not meet the eligibility
                    criteria for the scholarship. <br />
                    <strong className="font-bold">
                        If you have questions or believe there may be an error,<br />
                        please contact us at <a href='mailto:hello@northcoders.com' className='text-primary'>hello@northcoders.com</a>
                    </strong>
                </p>
                <p className='text-center font-medium mt-6'>
                    There are other bootcamps available with finance options <br /> including <strong>£500</strong> discount when you pay in advance and Interest Free payments. <br /><br />

                    Find out more about our self funded bootcamps <a href='https://northcoders.com/our-courses/' className='text-primary'>here</a>
                </p>
                <div className="flex justify-center my-8">
                    <Button tabIndex={0} color="primary" className="cursor-pointer" onClick={closeModal} icon linkType={'Document'} href='https://northcoders.com/apply'>
                        Close
                    </Button>
                </div>
            </div>

            <LogoSymbolRed className="absolute -left-20rem top-1/4 w-3/4 z-skillsEligibilityCheckerModelLogo" />
        </div>
    )
}
