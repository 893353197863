import React, { useEffect, useState } from "react";

// Local
import Button from "../../../../shared/Button/Button";
import HtmlContent from "../../../../shared/HtmlContent/HtmlContent";

// Assets
import SkillsForLife from "../../../../../assets/png/skills-for-life-bootcamps.png";
import LogoOne from "../../../../../assets/img/triangles/one.svg";
import LogoTwo from "../../../../../assets/img/triangles/two.svg";
import LogoThree from "../../../../../assets/img/triangles/three.svg";

const states = {
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
  IN_PROGRESS: "IN_PROGRESS",
};

export default function InProgress({
  questions,
  pageInfo,
  setPageInfo,
  currentPage,
  setCurrentPage,
  setShowingNextButton,
  showingNextButton,
  setCurrentState,
  hasFailed,
  setHasFailed,
}) {
  const [isChangingPage, setIsChangingPage] = useState(false);

  const logos = {
    1: LogoOne,
    2: LogoTwo,
    3: LogoThree,
  };
  const LogoElement = logos[currentPage + 1];

  return (
    <div
      id="content"
      className={`
        relative z-skillsEligibilityCheckerModelContent
        ${
          // If we're changing page, we want the content to slide out to the left,
          // and the new content to slide in from the right.
          isChangingPage
            ? "transition-transform duration-200 transform translate-x-6"
            : ""
        }
      `}
    >
      <h1
        className="
        text-black text-xl md:text-3xl font-bold
        pl-6
        flex justify-between items-center
        relative
        "
      >
        <div>{pageInfo[currentPage].title}</div>
        <img
          src={SkillsForLife}
          alt="Skills for Life"
          className="w-16 md:w-24 relative mr-6"
          style={{
            zIndex: 1000000,
          }}
        />
      </h1>
      <div
        className="
          relative

          grid

          grid-cols-1
          md:grid-cols-skillsEligibilityModalContentMedium
          lg:grid-cols-skillsEligibilityModalContentLarge
        "
      >
        <div
          className="
              break-words
              overflow-hidden
            "
        >
          {questions.map((question, index) => {
            const { title } = question;

            let additionalDescription = "";
            if (question.additionalDescription !== undefined) {
              additionalDescription = question.additionalDescription;
            }

            const showNextQuestion = () => {
              setShowingNextButton(false);
              setHasFailed(false);

              if (question.doNotAllowYes) {
                setHasFailed(true);
                setShowingNextButton(true);
                return;
              }

              if (!pageInfo[currentPage].questions[index + 1]) {
                setShowingNextButton(true);
                return;
              }

              setPageInfo((prevState) => {
                const newState = [...prevState];
                newState[currentPage].questions[index + 1].hidden = false;
                return newState;
              });
            };

            const hideNextQuestion = () => {
              // As any question answered with a No instantly fails the eligibility checker,
              // we want to always show the next button.
              setHasFailed(true);
              setShowingNextButton(true);

              // If this question object has 'allowNo' set to true,
              // then we want to show the next question.
              if (question.allowNo !== undefined && question.allowNo === true || question.doNotAllowYes) {
                setHasFailed(false);
                return;
              }

              // After this, we want to hide the next question, if there is one.
              if (!pageInfo[currentPage].questions[index + 1]) {
                return;
              }

              // Set every question after this one to be hidden.
              setPageInfo((prevState) => {
                const newState = [...prevState];
                newState[currentPage].questions.forEach(
                  (question, questionIndex) => {
                    if (questionIndex > index) {
                      question.hidden = true;
                    }
                  }
                );
                return newState;
              });
            };

            return (
              <ul
                key={`page-${currentPage}question-${index}`}
                className={`transition-opacity duration-200
                ${
                  question.hidden
                    ? "opacity-0 invisible"
                    : "opacity-100 visible"
                }
                ${isChangingPage ? "" : "px-6 my-2 relative"}
              `}
                style={{
                  zIndex: 1000000,
                }}
              >
                <li className="">
                  <HtmlContent
                    html={title.toString()}
                    className='c-prose text-black text-sm md:text-lg font-bold w-full leading-5 bg-white pr-2 pt-1 pb-1 inline'
                  />
                </li>
                <ol className="mb-2">
                  <li className="flex flex-row items-center">
                    <input
                      type="radio"
                      name={`page-${currentPage}question-${index}`}
                      id={`page-${currentPage}question-${index}-answer-one`}
                      className="block bg-primary"
                      onClick={showNextQuestion}
                    />
                    <label
                      htmlFor={`page-${currentPage}question-${index}-answer-one`}
                      className="text-black text-sm md:text-base font-medium ml-3 cursor-pointer"
                    >
                      {question.answers[0].title}
                    </label>
                  </li>

                  <li className="flex flex-row items-center">
                    <input
                      type="radio"
                      name={`page-${currentPage}question-${index}`}
                      id={`page-${currentPage}question-${index}-answer-two`}
                      className="block bg-primary"
                      onClick={hideNextQuestion}
                    />
                    <label
                      htmlFor={`page-${currentPage}question-${index}-answer-two`}
                      className="text-black text-sm md:text-base font-medium ml-3 cursor-pointer"
                    >
                      {question.answers[1].title}
                    </label>
                  </li>
                </ol>
                <HtmlContent
                  html={additionalDescription.toString()}
                  className={`
                c-prose
                w-full lg:w-3/4
                z-skillsEligibilityCheckerModelContent
                text-xs lg:text-sm
                inline
                bg-white
                leading-normal
              `}
                />
              </ul>
            );
          })}
          <div
            className="
              pl-6 pb-2
              relative
            "
          >
            <Button
              tabIndex={0}
              color="primary"
              className={`
                      transition-opacity ${
                        isChangingPage ? "duration-0" : "duration-200"
                      }
                      ${
                        showingNextButton
                          ? "opacity-100 visible"
                          : "opacity-0 invisible"
                      }
                      mt-2
                      cursor-pointer
                      shadow-xl
                      py-4
                  `}
              icon
              onClick={(index) => {
                if (isChangingPage) {
                  return;
                }
                setIsChangingPage(true);

                setShowingNextButton(false);
                setCurrentPage(currentPage + 1);

                if (hasFailed) {
                  // User has clicked no somewhere
                  setCurrentState(states.FAIL);
                  return;
                }

                if (!pageInfo[currentPage + 1]) {
                  // We have completed the eligibility checker.
                  setCurrentState(states.SUCCESS);
                  return;
                }

                // Set the next page to be visible.
                // Un-tick all radio buttons.
                const radioButtons = document.querySelectorAll(
                  `input[name="page-${currentPage}question-${index}"]`
                );

                radioButtons.forEach((radioButton) => {
                  radioButton.checked = false;
                });

                setIsChangingPage(false);
              }}
            >
              {pageInfo[currentPage + 1] ? "Next" : "Check eligibility"}
            </Button>

            {/* <LogoElement className="hidden md:block absolute -right-40 -top-15rem lg:-right-10rem md:-top-25rem w-3/4 z-skillsEligibilityCheckerModelLogo" /> */}
            {/* <LogoSymbolRed className="hidden md:block absolute -right-40 -top-15rem lg:-right-25rem md:-top-25rem w-3/4 z-skillsEligibilityCheckerModelLogo" /> */}
          </div>
        </div>

        <div
          className="
            hidden md:block
            z-skillsEligibilityCheckerModelLogo

            absolute
            -right-15rem lg:-right-40 xl:-right-10rem
            top-1/4
        "
        >
          <LogoElement className="" />
        </div>
      </div>
    </div>
  );
}
