import React from "react";

// Local
import Button from "../../../../shared/Button/Button";

// Assets
import LogoSymbolRed from "../../../../../assets/img/logo-symbol-red.svg";
import SkillsForLife from '../../../../../assets/png/skills-for-life-bootcamps.png';

export default function Success() {
  return (
    <div
      id="content"
      className="relative min-h-500px grid items-center align-middle"
    >
      <div>
        <div>
          <img src={SkillsForLife} alt="Skills for Life" className="w-32 mx-auto mb-12" />
          <div>
            <h2 className="text-black text-4xl font-bold mb-2 ml-6 text-center">
              Congratulations!
            </h2>
            <p className="text-center font-medium">
              Based on your responses, you appear to be eligible for the
              scholarship. <br />
              <strong className="font-bold">
                You can apply for our bootcamp below!
              </strong>
            </p>
          </div>
        </div>
        <div
          className="flex justify-center my-8 relative"
          style={{ zIndex: 1000000 }}
        >
          <Button
            tabIndex={0}
            color="primary"
            className='cursor-pointer'
            icon
            isBroken={false}
            linkType={'Document'}
            target={null}
            url={'https://northcoders.com/apply'}
          >
            Apply
          </Button>
        </div>
      </div>

      <LogoSymbolRed className="absolute -left-20rem top-1/4 w-3/4" />
    </div>
  );
}
