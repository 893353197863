import React, { useState, useCallback, useEffect } from "react";

// States
import Success from "./states/Success";
import Fail from "./states/Fail";
import InProgress from "./states/InProgress";

// Assets
import ControlArea from "./SkillsEligibilityCheckerControlsArea";
import ModalWrapper from "./SkillsEligibilityCheckerModalWrapper";

const states = {
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
  IN_PROGRESS: "IN_PROGRESS",
};

export default function SkillsEligibilityCheckerModal({ closeModal }) {
  const [pageInfo, setPageInfo] = useState([
    {
      title: "Basic Eligibility",
      questions: [
        {
          title: "Were you aged 19 or older on 31st August 2024?",
          answers: [
            {
              title: "Yes",
            },
            {
              title: "No",
            },
          ],
        },
        {
          title: "Do you live in England?",
          answers: [
            {
              title: "Yes",
            },
            {
              title: "No",
            },
          ],
          hidden: true,
        },
        {
          title: "Will you actively search for a job in tech immediately after graduating the bootcamp?",
          answers: [
            {
              title: "Yes",
            },
            {
              title: "No",
            },
          ],
          hidden: true,
        },
        {
          title:
            "Are you able to commit to the course on a full-time basis, Monday-Friday 8.30-5pm for 13 weeks?",
          answers: [
            {
              title: "Yes",
            },
            {
              title: "No",
            },
          ],
          hidden: true,
        },
      ],
    },
    {
      title: "Residency & Learning Background",
      questions: [
        {
          title:
            "Do you meet the residency requirements?",
          answers: [
            {
              title: "Yes",
            },
            {
              title: "No",
            },
          ],
          additionalDescription: `<p><strong>Note:</strong> In most instances, you must have lived in the UK for at least the last 3 years. Click <a href='https://www.gov.uk/government/publications/adult-education-budget-aeb-funding-rules-2022-to-2023/adult-education-budget-aeb-funding-rules-2022-to-2023#residency' target='_blank'>here</a> to view the full requirements and see if you are exempt. If you are not a UK national, we require proof of your residency in the UK for the last 3 years e.g. immigration share code, BRP, etc.</p>`,
        },
        {
          title:
            "Have you taken part in previous formal education similar to chosen bootcamp?",
          answers: [
            {
              title: "Yes",
            },
            {
              title: "No",
            },
          ],
          hidden: true,
          additionalDescription: `<p><strong>Note:</strong> The bootcamp should provide new learning opportunities for scholarship students. Any self taught learning you've done is fine.</p>`,
          doNotAllowYes: true,
        },
      ],
    },
    {
      title: "Equipment & Entry Challenge",
      questions: [
        {
          title: "Do you have your own laptop to use for this course?",
          answers: [
            {
              title: "Yes",
            },
            {
              title: "No",
            },
          ],
          additionalDescription: `<p>Please find out about the minimum specifications required <a href='https://northcoders.com/company/blog/what-computer-do-i-need-for-the-northcoders-coding' target="_blank">here</a></p>`,
        },
        {
          title:
            "Do you understand that you will need to pass our Entry Challenge as part of the application process?",
          answers: [
            {
              title: "Yes",
            },
            {
              title: "No",
            },
          ],
          hidden: true,
        },
      ],
    },
  ]);
  const [currentPage, setCurrentPage] = useState(0);
  const [showingNextButton, setShowingNextButton] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const [currentState, setCurrentState] = useState(states.IN_PROGRESS);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, []);

  useEffect(() => {
    // Re-hide all but the first question when the page is changed.
    setPageInfo((prevState) => {
      const newState = [...prevState];
      if (newState[currentPage] !== undefined) {
        newState[currentPage].questions.forEach((question, index) => {
          if (index !== 0) {
            question.hidden = true;
          }
        });
        return newState;
      }
      return prevState;
    });
  }, [currentPage]);

  const handleKeyDown = useCallback((event) => {
    if (event.keyCode === 27) {
      // escape key
      closeModal();
    }
  }, []);

  const goBack = () => {
    // Are we on the first page? If so, we don't want to go back.
    if (currentPage === 0) {
      return;
    }

    // If we're going back a page, we want to make sure
    // the next button is hidden.
    setShowingNextButton(false);

    // We're going back a page, so we want to make sure we force
    // the user back into the IN_PROGRESS state, so they do not
    // constantly see the fail or success screens.
    if (currentState !== states.IN_PROGRESS) {
      setCurrentState(states.IN_PROGRESS);
    }

    setCurrentPage(currentPage === 0 ? currentPage : currentPage - 1);
  };

  return (
    <ModalWrapper closeModal={closeModal}>
        <ControlArea
          closeModal={closeModal}
          currentPage={currentPage}
          goBack={goBack}
        />

        {currentState === states.IN_PROGRESS ? (
          <InProgress
            questions={pageInfo[currentPage].questions}
            pageInfo={pageInfo}
            setPageInfo={setPageInfo}
            currentPage={currentPage}
            currentState={currentState}
            setCurrentState={setCurrentState}
            showingNextButton={showingNextButton}
            setShowingNextButton={setShowingNextButton}
            hasFailed={hasFailed}
            setHasFailed={setHasFailed}
            states={states}
            setCurrentPage={setCurrentPage}
          />
        ) : currentState === states.FAIL ? (
          <Fail closeModal={closeModal} />
        ) : currentState === states.SUCCESS ? (
          <Success closeModal={closeModal} />
        ) : null}
      </ModalWrapper>
  );
}
