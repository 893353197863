import React from "react";

import BackIcon from "../../../../assets/img/logo-back-icon.svg";
import CloseIcon from "../../../../assets/img/logo-close-icon.svg";

export default function ControlArea({
    currentPage,
    goBack,
    closeModal,
}) {
  const canSeeBackButton = currentPage !== 0;
  return (
    <div
      className="
        px-6 pt-2
        flex flex-row justify-between
        relative
      "
      style={{
        zIndex: 1000000,
      }}
    >
      <BackIcon
        className={`w-4 cursor-pointer ${canSeeBackButton ? "visible" : "invisible"}`}
        onClick={goBack}
      />

      <CloseIcon className="w-4 cursor-pointer" onClick={closeModal} />
    </div>
  );
}
