import React from 'react'
import Button from '../../shared/Button/Button'

export default function CourseViewModal({
  closeModal,
}) {
  return (
    <div
      id="course-modal"
      className='
          fixed
          z-lowModel
          bg-white
          shadow-2xl
          py-4 px-6
          w-full md:w-auto md:max-w-lg
          bottom-0 md:right-0
          border-b-4 border-t-2 border-primary
          mr-6 lg:mb-6
        '
      tabIndex={0}
    >
      <h1 className='text-2xl font-bold mb-2 p-0'>Want to get into tech but confused which subject is best for you?</h1>
      <p className='text-base leading-6 tracking-normal text-gray-600'>Deciding which subject is right for you can be difficult. Our quiz will ask a few questions like <em>'What's your fave type of film?'</em> and help you choose the course that's best for you based on your answers.</p>

      <div className='flex justify-between flex-wrap'>
        <Button
          color='primary'
          className='mt-6 cursor-pointer focus:border-black'
          target={'_blank'}
          url={'/sorting-quiz'}
          icon
        >
          View our quiz
        </Button>

        <Button
          color='secondary'
          className='mt-6 cursor-pointer'
          onClick={closeModal}
          tabIndex={1}
        >
          Close
        </Button>
      </div>
    </div>
  )
}
