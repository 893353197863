/**
 * Track how many times a user has visited the site based on localStorage.
 * @return {number} amount of times visited today
 */
const timesVisitedToday = () => {
    if (typeof window === 'undefined') return 0;

    const localStorage = window.localStorage;
    const timesVisitedToday = localStorage.getItem('timesVisitedToday');
    const today = new Date().toDateString();

    // Check if the localStorage has the keys we need and has not been tampered with.
    const doesHaveTodayAndVisitedKeys = timesVisitedToday && timesVisitedToday.includes('today') && timesVisitedToday.includes('visited');

    if (!timesVisitedToday || !doesHaveTodayAndVisitedKeys) {
        // First time visiting today
        localStorage.setItem('timesVisitedToday', `today:${today};visited:1`);
        return 1;
    } else {
        const [key, value] = timesVisitedToday.split(';');
        const [keyName, date] = key.split(':');
        const [valueName, count] = value.split(':');

        if (date === today) {
            // Visited today
            localStorage.setItem('timesVisitedToday', `${keyName}:${date};${valueName}:${parseInt(count) + 1}`);
        } else {
            // First time visiting today
            localStorage.setItem('timesVisitedToday', `today:${today};visited:1`);
        }

        return parseInt(count);
    }
}

export default timesVisitedToday;
