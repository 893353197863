import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";

// Layout
import Seo from "../components/layout/Seo/Seo";

// Views
import CourseView from "../components/views/CourseView/CourseView";
import CourseViewModal from "../components/views/CourseView/CourseViewModal";

// Utils
import getCustomRobotsFromTags from "../utils/getCustomRobotsFromTags";
import timesVisitedToday from "../utils/timesVisitedToday";
import SkillsEligibilityCheckerModal from "../components/elements/_custom/SkillsEligibilityChecker/SkillsEligibilityCheckerModal";
import Button from "../components/shared/Button/Button";

const CourseViewTemplate = ({ data, location }) => {
  const tags = data.prismicCourse.tags;
  const robots = getCustomRobotsFromTags(tags);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSkillsEligibilityModalOpen, setIsSkillsEligibilityModalOpen] =
    useState(false);
  const [hasModalBeenClosed, setHasModalBeenClosed] = useState(false);

  const amountVisitedToday = timesVisitedToday();

  let timerAmount = 30; // Default to 30 seconds.
  if (amountVisitedToday === 1) {
    timerAmount = 50; // If it's the first time visiting today, wait 50 seconds before showing the modal.
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      // This happens after the timerAmount has passed, either 10 or 30 seconds,
      // depending on if it's the first time visiting today or not.

      // If the modal is already open, don't open it again.
      if (isModalOpen) {
        return;
      }
      // If the modal has been closed, don't open it again.
      if (hasModalBeenClosed) {
        return;
      }

      // If the modal is not open, open it.
      setIsModalOpen(true);
    }, timerAmount * 1000);

    return () => clearTimeout(timer);
  }, [amountVisitedToday, timerAmount]);

  const closeModal = () => {
    setIsModalOpen(false);
    setHasModalBeenClosed(true);
  };
  const closeSkillsEligibilityModal = () => {
    setIsSkillsEligibilityModalOpen(false);
  };
  const openSkillsEligibilityModal = () => {
    setIsSkillsEligibilityModalOpen(true);
  };

  const isSkillsBootcamp = location.pathname.includes('skills-bootcamp');
  const isLimitedCoursePage = tags.includes('limited-course-page');
  const whichCourse = location.pathname.includes('software') ? 'software' : location.pathname.includes('data') ? 'data' : 'cloud';
  const forceSkillsEligibilityModal = location.search === '?openChecker';

  useEffect(() => {
    if (forceSkillsEligibilityModal) {
      setIsSkillsEligibilityModalOpen(true);
    }
  }, [forceSkillsEligibilityModal]);

  const path = location.pathname; // this can be /our-courses/part-time-coding-bootcamp
  const pathArray = path.split("/");
  const courseSlug = pathArray[pathArray.length - 1];
  const isPartTime = courseSlug.includes("part-time");

  return (
    <>
      <Seo
        title={data.prismicCourse.data.title.text}
        customTitle={data.prismicCourse.data.meta_title}
        description={
          data.prismicCourse.data.meta_description.text ||
          data.prismicCourse.data.course_intro.text
        }
        image={data.prismicCourse.data.social_image.url}
        slug={location.pathname}
        robots={robots}
      />
      {isModalOpen ? <CourseViewModal closeModal={closeModal} /> : null}
      {(isSkillsEligibilityModalOpen && isSkillsBootcamp) ? <SkillsEligibilityCheckerModal closeModal={closeSkillsEligibilityModal} /> : null}
      <CourseView
        data={data.prismicCourse.data}
        openSkillsEligibilityModal={openSkillsEligibilityModal}
        doesShowEligibility={isSkillsBootcamp}
        currentCourse={whichCourse}
        isLimitedCoursePage={isLimitedCoursePage}
        isPartTime={isPartTime}
      />
    </>
  );
};

export default withPreview(CourseViewTemplate);

export const PageQuery = graphql`
  query CourseViewTemplateQuery($uid: String!) {
    prismicCourse(uid: { eq: $uid }) {
      uid
      tags
      data {
        title {
          text
          html
          raw
        }
        meta_title
        meta_description {
          text
        }
        social_image {
          url
        }
        apply_cta_link {
          ...Link
        }
        curriculum_form_guid
        logo_group {
          ...LogoGroup
        }
        course_intro {
          html
          text
        }
        course_illustration {
          ...Image
        }
        course_header {
          text
        }
        course_body {
          html
        }
        price_heading {
          text
          html
        }
        course_start_date(formatString: "Do MMMM YYYY")
        start_date_body {
          html
        }
        course_location {
          text
        }
        course_duration {
          text
        }
        duration_body {
          html
        }
        course_duration_days {
          text
        }
        course_duration_times {
          text
        }
        comparison_table_heading {
          html
          text
        }
        comparison_table_body {
          text
          html
        }
        compare_against {
          text
        }
        compare_against_price {
          text
        }
        compare_against_extra_info {
          text
        }
        compare_to {
          text
        }
        compare_to_price {
          text
        }
        compare_to_extra_info {
          text
        }
        comparison_table_content {
          content_label {
            text
          }

          does_compare_against_have
          does_compare_to_have
        }
        course_price_total
        price_body {
          text
          html
        }
        price_total {
          text
        }
        start_date_bullets {
          html
          raw
          text
        }
        duration_bullets {
          html
          raw
          text
        }
        price_bullets {
          html
          raw
          text
        }
        price_total {
          text
        }
        course_price_breakdown {
          course_price_breakdown_price
          course_price_breakdown_description {
            text
          }
          course_price_breakdown_extra_info {
            html
          }
        }
        course_video_embed {
          ...Embed
        }
        course_video_embed_custom_title
        course_image_embed {
          ...Image
        }
        course_features {
          course_features_icon {
            ...Image
          }
          course_features_header {
            text
            raw
          }
          course_features_body {
            html
          }
        }
        image_section_title {
          text
        }
        image_section_body_above {
          html
          text
        }
        image_section_image {
          ...Image
        }
        image_section_body_below {
          html
          text
        }
        course_curriculum_title {
          text
        }
        course_curriculum_tabs {
          course_curriculum_tab_value {
            text
          }
          course_curriculum_tab_header {
            text
          }
          course_curriculum_tab_body {
            html
          }
          course_curriculum_tab_footnote {
            html
          }
        }
        course_curriculum_additional_info_grid {
          course_curriculum_additional_info_grid_icon {
            ...Image
          }
          course_curriculum_additional_info_grid_header {
            text
            raw
          }
          course_curriculum_additional_info_grid_body {
            html
          }
        }
        course_dates_title {
          text
        }
        course_dates {
          course_dates_location {
            text
          }
          course_dates_extra_label {
            text
          }
          course_dates_date(formatString: "Do MMMM YYYY")
          course_dates_display_apply_now_button
          send_to_self_funded_page
        }
        course_finance_title {
          text
        }
        course_finance_rows_with_icon {
          course_finance_row_icon {
            ...Image
          }
          course_finance_row_body {
            html
          }
          cta_label
          cta_link {
            ...Link
          }
        }
        course_finance_rows {
          body {
            html
          }
          cta_label
          cta_link {
            ...Link
          }
        }
        course_testimonial_carousel {
          isBroken
          document {
            ...TestimonialCarousel
          }
        }
        course_faqs_title {
          text
        }
        course_faqs_tabs {
          course_faqs_header {
            text
          }
          course_faqs_body {
            html
          }
        }
        course_faqs_cta_label {
          text
        }
        course_faqs_cta_link {
          ...Link
        }

        part_time_testimonials {
          testimonial_image {
            ...Image
          }

          testominal_name {
            text
          }
          testominal_tag {
            text
          }
          testimonial_description {
            text
            html
          }
        }
      }
    }
  }
`;
